body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

root {
  font-size: 16;
}

/* clear background */
h2 {
  text-align: center;
  padding: 16px;
  margin: 0;
}

/* crops animations that exceeds one line area */
.line {
  width: 100%;
  height: 4rem;
  overflow: hidden;
  border: 1px solid black;
  padding: 0;
  margin-bottom: 16px;
}

/* lineup class and keyframes */
.lineUp {
  animation: 4s anim-lineUp ease-out 1;
  animation-fill-mode: both;
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateX(80%);
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: translateX(0%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}